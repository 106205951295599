import "./App.css";
import Navbar from "./components/navbar/navbar";
import HeroSection from "./components/hero-section/HeroSection";
import OurMission from "./components/our-mission/OurMission";
import CallBack from "./components/callback/CallBack";
import Animations from "./components/Animations";
import ImageSequence from "./components/image-sequence/ImageSequence";
import SideBySideImageText from "./components/side-image-widget/SideBySideImageText";
import PDFDownloadSection from "./components/pdf-download/PDFDownloadSection";
import FamilyOfBrands from "./components/brands/Brands";
import BadgeBanner from "./components/badge-banner/BadgeBanner";
import VideoSection from "./components/video-section/VideoSection";
import { videoData, unitwistImages, clickcloseImages } from "./staticData";

// Helper component to group related content (e.g., VideoSection with additional components)
const VideoSectionGroup = ({ video, extraComponents }) => (
  <>
    <VideoSection
      videoUrl={video.videoUrl}
      videoUrlSub={video.videoUrlSub}
      posterUrl={video.posterUrl}
      heading={video.heading}
      descText={video.descText}
      logoUrl={video.logoUrl}
    />
    {extraComponents}
  </>
);

function App() {
  return (
    <>
      <Navbar />
      <HeroSection
        videoUrl={videoData.hero.videoUrl}
        posterUrl={videoData.hero.posterUrl}
        videoUrlSub={videoData.hero.videoUrlSub}
      />

      <div style={{ paddingTop: "30px" }}>
        <Animations animationPath={videoData.animations.path} />
      </div>

      <BadgeBanner />
      <ImageSequence images={unitwistImages} />
      <OurMission />

      {/* High pressure section */}
      <VideoSectionGroup
        video={videoData.highPressure}
      />

      {/* Working with the system section */}
      <VideoSectionGroup
        video={videoData.workingWithSystem}
      />

      {/* Click close section */}
      <VideoSectionGroup
        video={videoData.clickClose}
        extraComponents={
          <>
            <SideBySideImageText
              imageUrl={videoData.sideBySideClickClose.imageUrl}
              heading={videoData.sideBySideClickClose.heading}
              body={videoData.sideBySideClickClose.body}
            />
            <ImageSequence images={clickcloseImages} />
          </>
        }
      />

      {/* Easy Bat section */}
      <VideoSectionGroup
        video={videoData.easyBat}
        extraComponents={
          <SideBySideImageText
            percentageWidth={true}
            imageUrl={videoData.easyBatSideBySide.imageUrl}
            heading={videoData.easyBatSideBySide.heading}
            body={videoData.easyBatSideBySide.body}
          />
        }
      />

      {/* Easy Tail section */}
      <VideoSectionGroup
        video={videoData.easyTail}
      />

      <PDFDownloadSection
        pdfURL={videoData.pdfDownload.pdfURL}
        descText={videoData.pdfDownload.descText}
        heading={videoData.pdfDownload.heading}
        imageURL={videoData.pdfDownload.imageURL}
      />

      <CallBack />
      <FamilyOfBrands />
    </>
  );
}

export default App;
