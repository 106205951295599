// data/staticData.js

export const unitwistImages = [
    "https://i.imgur.com/xstvXpi.jpeg",
    "https://i.imgur.com/7Uiolwa.jpeg",
    "https://i.imgur.com/wNyPQ5Q.jpeg",
    "https://i.imgur.com/FNEglot.jpeg",
    "https://i.imgur.com/3UHqj9v.jpeg",
    "https://i.imgur.com/77brFyQ.jpeg",
    "https://i.imgur.com/xjhOxD7.jpeg",
    "https://i.imgur.com/P2OjkLJ.jpeg",
];

export const clickcloseImages = [
    "https://i.imgur.com/GnwQmt0.jpg",
    "https://i.imgur.com/469aTXE.jpg",
    "https://i.imgur.com/YAJFdVP.jpg",
    "https://i.imgur.com/4mgCAZH.jpg",
    "https://i.imgur.com/W9w5aa8.jpg",
];

export const videoData = {
    hero: {
        videoUrl: "994591600",
        posterUrl: "https://res.cloudinary.com/dme6fkmny/image/upload/v1718217283/behind_the_tex_tcc62z.png",
        videoUrlSub: "BEHIND_THE_TECHNOLOGY_-_UNITWIST_ENGLISH_mv8bkm",
    },
    animations: {
        path: "https://i.imgur.com/tojFVrQ.gif",
    },
    highPressure: {
        videoUrl: "994591787",
        posterUrl: "https://res.cloudinary.com/dme6fkmny/image/upload/v1718217307/highpressure_ppanqi.png",
        videoUrlSub: "UNITWIST_PRESSURE_ABILITY_wxi82l",
        heading: `High Pressure.\nNo Problem`,
        descText:
            "Unitwist is renowned for its robust design, capable of handling extremely high pressures. While our 40 bar rating complies with SABS testing regulations, we've achieved pressures up to 120 bar without failure.",
    },
    workingWithSystem: {
        videoUrl: "994591651",
        posterUrl: "https://res.cloudinary.com/dme6fkmny/image/upload/v1718218025/workingwith_oto1fh.png",
        videoUrlSub: "UNITWIST_-_WORKING_WITH_THE_SYSTEM_pem0nk",
        heading: "The DIY & Professional Plumbers’ Choice",
        descText:
            "Whether you're a professional plumber seeking a reliable, affordable solution or a DIY enthusiast working on your own plumbing projects, Unitwist is the best solution for most applications.",
    },
    clickClose: {
        videoUrl: "994591712",
        posterUrl: "https://res.cloudinary.com/dme6fkmny/image/upload/v1718217285/cc_n88izz.png",
        logoUrl: "https://i.imgur.com/Z1ZOFk3.png",
        heading: "South Africa’s Most Popular Taps",
        descText:
            "Our taps are the most purchased outdoor tap range in South. Featuring a patented slip-clutch valve, our taps offer numerous advantages over traditional taps and come with a 5-year guarantee.",
    },
    sideBySideClickClose: {
        imageUrl: "https://i.imgur.com/23u2GCd.png",
        heading: "Lasts Longer.\nSaves Water",
        body: "This valve vastly reduces the wear and tear of the washer, experienced by traditional valves which then leak and require ongoing maintenance. Aside from the valve, the design is extremely robust and is able to endure harsh conditions, cannot be over-tightened, and is UV stabilized.",
    },
    easyBat: {
        videoUrl: "994596885",
        posterUrl: "https://res.cloudinary.com/dme6fkmny/image/upload/v1718217310/easybat_aoxf8x.png",
        logoUrl: "https://i.imgur.com/I3YfMra.png",
        heading: "One-Piece MasterBats",
        descText:
            "Our range of pipe clips are crafted for convenience, featuring a one-piece molded design that eliminates the common hassles of lost or neglected caps, ensuring tidier installations.",
    },
    easyBatSideBySide: {
        imageUrl: "https://i.imgur.com/aGSuTzo.png",
        heading: "Designed For Convenience",
        body: "All our EasyBat installations are neat, tidy, and notably quick and easy. Despite their compact design, EasyBats use virgin-grade materials for enhanced strength and versatility, and they have a high weight tolerance.",
    },
    easyTail: {
        videoUrl: "994591847",
        posterUrl: "https://res.cloudinary.com/dme6fkmny/image/upload/v1718217290/easytail_lzfjra.png",
        logoUrl: "https://i.imgur.com/D06FYV6.png",
        heading: "Save Time & Money. Reduce Leak Risks.",
        descText:
            "Our product line is designed for the water meter market and features innovative, tool-free fittings with a slip nut for easy installation and removal, reducing leak risks and the need for multiple fittings. Made from durable plastic, these fittings are less prone to theft and are versatile enough for use in water purification and pump installations.",
    },
    pdfDownload: {
        pdfURL: "/UPDATED_UNITWIST_CATALOGUE_2024.pdf",
        heading: "View Our Range",
        descText:
            "Our range is available in 15 & 22mm sizes. 29 different fittings are available, providing you with solutions to many different plumbing applications. The system is approved for both Hot & Cold water.",
        imageURL: "https://i.imgur.com/1VM4g7c.jpeg",
    },
};
